import React from 'react';
import { type ClassValue, clsx } from 'clsx';
import { useLaravelReactI18n } from 'laravel-react-i18n';
import { twMerge } from 'tailwind-merge';
import { EFormatStatus, ETeamType, EUserRole, User } from '@/types';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function basename(str: string) {
  return str?.split('\\')?.pop()?.split('/').pop();
}

export function userIsBroadcaster(user: User): boolean {
  return user.current_team.type === ETeamType.Broadcaster;
}

export function userHasAdminAccess(user: User): boolean {
  return user.current_team.type !== ETeamType.Broadcaster && ![EUserRole.Viewer, EUserRole.Guest].includes(user.role);
}

export function renderFormatStatus(status: EFormatStatus) {
  const { t } = useLaravelReactI18n();

  switch (status) {
    case EFormatStatus.Draft:
      return (
        <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-amber-100 text-amber-800 rounded-full dark:bg-amber-500/10 dark:text-amber-500">
          {t('Draft')}
        </span>
      );
    case EFormatStatus.Review:
      return (
        <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-indigo-100 text-indigo-800 rounded-full dark:bg-indigo-500/10 dark:text-indigo-500">
          {t('Review')}
        </span>
      );
    case EFormatStatus.Published:
      return (
        <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-green-100 text-green-800 rounded-full dark:bg-green-500/10 dark:text-green-500">
          {t('Published')}
        </span>
      );
    default:
      return null;
  }
}
